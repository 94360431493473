/* eslint-disable react/jsx-no-comment-textnodes */
import * as React from 'react'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import Layout from 'components/Layout'
import FeaturedLink from '../components/FeaturedLink/FeaturedLink'
import QuoteSlider from '../components/QuoteSlider/QuoteSlider'
import ApplyToday from 'components/careers/ApplyToday'
import careersContent from '../../content/careers'
import Seo from '../components/Seo'

const CareersPage = () => {
  const { featuredLink, quoteSlider } = careersContent
  return (
    <Layout>
      {/* HERO */}
      <div className="bg-careers-hero">
        <div className="pb-30 pt-25 inner-container">
          {/* top row */}
          <div className="flex flex-row">
            <div className="hidden md:block md:w-1/4 lg:pb-6">
              <StaticImage
                src="../images/careers/Xanadu2080_CROP.jpg"
                className="w-full h-full"
                alt=""
              />
            </div>
            <div className="w-full md:w-3/4 md:pl-6 lg:pl-32">
              <p className="subtitle">// Join Xanadu</p>
              <h1 className="mt-5">
                Hardware, software, algorithms. We are building the next
                generation of computation.
              </h1>
              <p className="mt-5">
                Xanadu is looking for world-class talent eager to take on
                challenging projects that will ultimately enable fault tolerant
                quantum computers with millions of qubits. We are an equal
                opportunity employer offering competitive compensation,
                comprehensive benefits and flexibility.
              </p>
              <div className="flex-col flex-wrap items-center mt-10 mb-5">
                <a
                  href="https://xanadu.applytojob.com/apply"
                  target="_blank"
                  className="mb-5 mx-2.5 btn btn-lg btn-1"
                  rel="noopener noreferrer"
                >
                  Open Positions
                </a>
                <Link to="/residency" className="mx-2.5 btn btn-lg btn-2">
                  Residency Program
                </Link>
              </div>
            </div>
          </div>
          {/* bottom row */}
          <div className="flex flex-row lg:h-114">
            <div className="flex-none hidden w-1/4 lg:block pb-28">
              <StaticImage
                src="../images/careers/MEL_0004_CROP.jpg"
                className="w-full h-auto"
                alt=""
              />
            </div>
            <div className="w-full pt-6 lg:pt-28">
              <div className="lg:px-6">
                <StaticImage
                  src="../images/careers/MEL_0150.jpg"
                  className="w-full h-auto"
                  alt=""
                />
              </div>
            </div>
            <div className="flex-none hidden w-1/4 lg:block pb-28">
              <StaticImage
                src="../images/careers/Xanadu2052_CROP.jpg"
                className="w-full h-auto"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
      {/* CULTURE */}
      <FeaturedLink
        title={featuredLink.title}
        subtitle={featuredLink.subtitle}
        description={featuredLink.description}
        link={featuredLink.link}
        image={featuredLink.image}
        swipeLink={true}
      />
      {/* OUR TEAM */}
      <QuoteSlider content={quoteSlider} />
      {/* APPLY TODAY */}
      <div className="standard-page-section inner-container pb-30">
        <ApplyToday />
      </div>
    </Layout>
  )
}

export default CareersPage

export const Head = () => {
  return (
    <Seo
      title="Careers"
      description="Take part in Xanadu's mission to build quantum computers that are useful and available to people everywhere"
      image="careers.jpg"
    />
  )
}
