/* eslint-disable react/jsx-no-comment-textnodes */
import { Link } from 'gatsby'
import * as React from 'react'

const ApplyToday = () => {
  return (
    <div className="flex flex-col items-center text-center">
      <p className="subtitle">// Join Xanadu</p>
      <h2 className="mt-2.5">Come make a difference with us</h2>
      <p className="mt-5 md:w-128">
        Work with our team of high performing quantum experts and builders,
        leading the path towards fault tolerant quantum computers.
      </p>
      <div className="mt-10 -mb-5">
        <a
          href="https://xanadu.applytojob.com/apply"
          target="_blank"
          className="mb-5 mx-2.5 btn btn-lg btn-1"
          rel="noopener noreferrer"
        >
          Open Positions
        </a>
        <Link to="/residency" className="mx-2.5 btn btn-lg btn-2">
          Residency Program
        </Link>
      </div>
    </div>
  )
}

export default ApplyToday
