export default {
  featuredLink: {
    title: `Our Culture`,
    subtitle: `Life at Xanadu`,
    description: `We have built a strong culture around learning, excellence and urgency, and we prioritize hiring the best people from all around the world. Xanadu employees regularly pioneer quantum technologies that lead to historical firsts that progress the world closer to universal fault tolerant quantum computing.`,
    link: { text: `Learn more`, url: `/values` },
    image: `culture_image.png`,
  },
  quoteSlider: {
    title: `Our Team`,
    subtitle: `The Xanadu experience`,
    quotes: [
      {
        image: 'robyn.jpg',
        quote: `The work environment at Xanadu is great! It allows me to put
        my skills to use while continuing to learn everyday.`,
        author: `Robyn, Hardware Operations`,
      },
      {
        image: 'fabian.jpg',
        quote: `A highly professional environment, with really ambitious
        goals. Everyday I get to learn from among the best in the
        world.`,
        author: `Fabian, Experimental Physicist`,
      },
      {
        image: 'young.jpg',
        quote: `As a researcher, Xanadu provides freedom, flexibility and
        support; there is always someone to ask for help.`,
        author: `Young, Quantum Photonic Engineer`,
      },
    ],
  },
}
